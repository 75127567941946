.account-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
}

    .account-header {
        display: flex;
        flex-direction: column;
        padding: 20px;
        border: 1px;
        border-radius: 10px;
        box-shadow: 2px 2px 2px 2px #efefef;
        background-color: #ffffff;
        gap: 10px;
    }

        .account-header-text {
            font-size: 18pt;
            font-weight: bold;
        }

    .account-box-container {
        display: flex;
        flex-direction: column;
        padding: 20px;
        border: 1px;
        border-radius: 10px;
        box-shadow: 2px 2px 2px 2px #efefef;
        background-color: #ffffff;
        gap: 15px;
    }

    .patient-information {
        display: flex;
        flex-direction: column;
    }

        .patient-information-title-box {
            display: flex;
            flex-direction: row;
        }

        .patient-information-title {
            color: slategray;
        }

        .patient-information-edit-button {
            padding-top: 3px;
            padding-left: 10px;
        }

        .patient-information-save-button {
            padding-top: 3px;
            padding-left: 10px;
        }

        .patient-information-value {
            font-weight: 600;
        }