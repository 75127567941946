.clinic-register-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 10%;
}

.clinic-register-options-container {
    display: flex;
    flex-direction: row-reverse;
}