.user-details-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 10%;
}

.user-details-fields-container {
    display: flex;
    flex-direction: column;
}

.user-details-options-container {
    display: flex;
    flex-direction: row-reverse;
}