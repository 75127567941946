.dropdown {
    display: inline-block;
    font-size: 12pt;
    position: relative;
    width: 100%;
}

    .dropdown.prompted {
        padding-bottom: 15px;
    }

.dropdown-title {
    font-size: 9pt;
    padding-left: 10px;
}

.dropdown-box {
    padding: 20px;
    border-radius: 5px;
    border-style: solid;
    border-width: 1.5px;
    border-color: #c4c4c4;
    height: calc(12pt + padding);
}

.dropdown-list {
    position: absolute;
    border-radius: 5px;
    border-style: solid;
    border-width: 0px;
    background-color: #ffffff;
    max-height: 150px;
    width: 100%;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    z-index: 1;
    box-shadow: 2px 2px 5px 0px #c4c4c4;
}

.dropdown-list-item {
    display: block;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #ffffff;
    transition: 500ms;
    cursor: pointer;
}

.dropdown-prompt {
    font-size: 9pt;
    padding-left: 10px;
    position: absolute;
    bottom: -10;
    left: 0%;
    white-space: nowrap;
    overflow: visible;
    padding-bottom: 20px;
}

.dropdown-list::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.dropdown-list-item:hover {
    background-color: #e9e9e9;
}

.dropdown:focus .dropdown-box {
    border-width: 2px;
    border-color: #5b346b;
    padding: 18px;
}

.dropdown-list-item.closed {
    display: none;
}

.dropdown-list.closed {
    display: none;
}