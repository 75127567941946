.register-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 425px;
    min-height: 500px;
    border-radius: 10px;
    border-style: solid;
    border-width: 1.5px;
    border-color: #c4c4c4;
    padding: 0%;
    position: relative;
}

.header {
    font-size: 20pt;
    font-family: Arial, Helvetica, sans-serif;
    padding: 0px 25px 0px 25px;
}

.subheader {
    font-size: 12pt;
    font-family: Arial, Helvetica, sans-serif;
    color: #616161;
    padding: 0px 25px 0px 25px;
}

.register-prompt-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    padding-top: 50px;
}

    .register-logo-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 95px;
        padding-right: 100px;
    }

.register-fields-container {
    padding-top: 10px;
    width: 80%;
}

.register-options-container {
    width: 80%;
    position: absolute;
    bottom: 40px;
}

@media screen and (max-width: 768px) {
    .register-container {
        position: absolute;
        border: 0px;
        width: 100%;
        top: 0%;
        left: 0%;
    }
}