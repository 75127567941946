.signup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 10%;
}

.os-option-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding-top: 20px;
}

.os-option-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 160px;
    cursor: pointer;
    padding-bottom: 20px;
}

    .os-option-image {
        height: 200px;
    }

    .os-option-text {
        font-size: 12pt;
        font-weight: bold;
    }