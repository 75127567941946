.clinic-name-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 10%;
}

.clinic-name-fields-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

    .clinic-name-firstname-field-container {
        display: flex;
        flex-direction: column;
    }

    .clinic-name-middlename-and-lastname-fields-container {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

.clinic-name-options-container {
    display: flex;
    flex-direction: row-reverse;
}