@media only screen and (max-width: 768px){
    .dashboard-container {
        display: flex;
        flex-direction: column;
    }

    .appointment-container {
        display: flex;
        flex-direction: column;
        padding: 20px;
        gap: 20px;
    }

    .appointment-container-header-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: baseline;
        padding: 20px;
        border: 1px;
        border-radius: 10px;
        box-shadow: 2px 2px 2px 2px #efefef;
        background-color: #ffffff;
        gap: 20px;
    }

        .appointment-container-header-name {
            font-size: 18pt;
            font-weight: bold;
        }

        .appointment-container-header-request-button {
            padding: 10px;
            border: 1px;
            border-radius: 10px;
            border-style: solid;
            border-color: #c4c4c4;
            cursor: pointer;
            transition: background-color 100ms;
            width: fit-content;

            user-select: none;
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
        }

            .appointment-container-header-request-button:hover {
                background-color: #efefef;
            }

            .appointment-container-header-request-button:active {
                border-color: transparent;
            }
            
    .balance-container {
        display: none;
    }

    .request-modal {
        position: fixed;
        top: 50%;
        bottom: 50%;
        left: 50%;
        right: 50%;
        transform: translate(-50%, -50%);

        width: 80%;
        height: fit-content;

        padding: 20px;
        border: 1px;
        border-radius: 10px;
        box-shadow: 2px 2px 2px 2px #efefef;
        background-color: #ffffff;
    }

        .request-modal-header {
            padding-bottom: 20px;
            font-size: 15pt;
        }

        .request-modal-fields {
            padding-bottom: 20px;
        }

        .request-modal-options {
            display: flex;
            flex-direction: row-reverse;
        }
}

@media only screen and (min-width: 769px) {
    .dashboard-container {
        display: flex;
        flex-direction: row;
        height: 100%;
    }

    .appointment-container {
        display: flex;
        flex-direction: column;
        padding: 20px;
        width: 50%;
        gap: 20px;
    }

        .appointment-container-header-container {
            align-items: center;
            display: flex;
            justify-content: space-between;
            padding: 20px;
            border: 1px;
            border-radius: 10px;
            box-shadow: 2px 2px 2px 2px #efefef;
            background-color: #ffffff;
            gap: 10px;
        }
    
            .appointment-container-header-name {
                font-size: 18pt;
                font-weight: bold;
            }

            .appointment-container-header-request-button {
                padding: 10px;
                border: 1px;
                border-radius: 10px;
                border-style: solid;
                border-color: #c4c4c4;
                cursor: pointer;
                transition: background-color 100ms;
                width: fit-content;

                user-select: none;
                -moz-user-select: none;
                -webkit-user-select: none;
                -ms-user-select: none;
            }

                .appointment-container-header-request-button:hover {
                    background-color: #efefef;
                }

                .appointment-container-header-request-button:active {
                    border-color: transparent;
                }

    .balance-container {
        display: flex;
        flex-direction: column;
        padding: 20px;
        width: 50%;
        gap: 20px;
    }

        .balance-box {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 20px;
            border: 1px;
            border-radius: 10px;
            box-shadow: 2px 2px 2px 2px #efefef;
            background-color: #ffffff;
            gap: 10px;
        }

            .balance-box-header {
                font-size: 18pt;
                font-weight: bold;
                padding-bottom: 20px;
                border-bottom: 1px solid #efefef;
            }

            .balance-box-contents-recent-payable {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding-top: 10px;
            }

                .balance-box-contents-recent-payable-title {
                    color: #c4c4c4;
                }

            .balance-box-contents-previous-balance {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding-bottom: 20px;
                border-bottom: 1px solid #efefef;
            }

                .balance-box-contents-previous-balance-title {
                    color: #c4c4c4;
                }

            .balance-box-contents-balance {
                display: flex;
                flex-direction: column;
                padding-top: 20px;
            }

                .balance-box-contents-balance-title {
                    color: #c4c4c4;
                    font-weight: bold;
                }

                .balance-box-contents-balance-amount {
                    color: #444444;
                    font-family: 'Roboto', sans-serif;
                    font-size: 21pt;
                    font-weight: bold;
                }
    
    .request-modal {
        position: fixed;
        top: 50%;
        bottom: 50%;
        left: 50%;
        right: 50%;
        transform: translate(-50%, -50%);

        width: 400px;
        height: fit-content;

        padding: 20px;
        border: 1px;
        border-radius: 10px;
        box-shadow: 2px 2px 2px 2px #efefef;
        background-color: #ffffff;
    }

        .request-modal-header {
            padding-bottom: 20px;
            font-size: 15pt;
        }

        .request-modal-fields {
            padding-bottom: 20px;
        }

        .request-modal-options {
            display: flex;
            flex-direction: row-reverse;
        }

        .history-modal {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            
            width: 90%; /* Adjust width to occupy most of the screen */
            max-width: 400px; /* Maximum width to prevent stretching on larger screens */
            max-height: 80vh; /* Limit the height to fit within the viewport */
            
            padding: 20px;
            border: 1px solid #ccc;
            border-radius: 10px;
            box-shadow: 2px 2px 2px 2px #efefef;
            background-color: #ffffff;
            
            display: flex;
            flex-direction: column; /* Make the content follow a column layout */
        }
        
        .history-modal .content {
            flex-grow: 1;
            overflow-y: auto; /* Enables vertical scrolling */
            margin-bottom: 10px; /* Space for the close button */
        }
}