.clinic-contact-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 10%;
}

.clinic-contact-fields-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.clinic-contact-options-container {
    display: flex;
    flex-direction: row-reverse;
}
::placeholder {
    color: rgba(78, 76, 76, 0.5); /* Adjust opacity to achieve faded effect */
}