@media only screen and (max-width: 768px) {
    .authentication-page {
        display: flex;
        flex-direction: row;
    }
    
    .authentication-page-main-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    
        .greeting-container {
            padding-top: 50px;
            padding-left: 40px;
            padding-right: 40px;
        }
    
            .logo-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-bottom: 15px;
            }
    
            .greeting {
                font-size: 40pt;
                font-weight: bold;
                font-family: 'Roboto', sans-serif;
                color: #5b346b;
            }
    
            .greeting-subtext {
                font-size: 15pt;
                font-family: Arial, Helvetica, sans-serif;
                padding-top: 10px;
                padding-left: 5px;
                padding-bottom: 30px;
            }
    
    .field-main-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-left: 40px;
        padding-right: 40px;
    }
    
        .field-container {
            display: flex;
            flex-direction: column;
        }
        
        .field-name {
            font-size: 9pt;
            padding-left: 10px;
        }
        
        .field {
            font-size: 12pt;
            padding: 20px;
            border-radius: 5px;
            border-style: solid;
            border-width: 1.5px;
            border-color: #c4c4c4;
            box-shadow: none;
        }

            .field:focus {
                outline-color: #5b346b;
            }
    
    .option-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-top: 30px;
        padding-left: 40px;
        padding-right: 40px;
    }
}

@media only screen and (min-width: 769px) {
    .authentication-page {
        display: flex;
        flex-direction: row;
        height: 100vh;
    }
    
    .authentication-page-main-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 600px;
    }
    
        .greeting-container {
            padding-top: 200px;
            padding-left: 95px;
            padding-right: 100px;
        }
    
            .logo-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-bottom: 15px;
            }
    
            .greeting {
                font-size: 40pt;
                font-weight: bold;
                font-family: 'Roboto', sans-serif;
                color: #5b346b;
            }
    
            .greeting-subtext {
                font-size: 15pt;
                font-family: Arial, Helvetica, sans-serif;
                padding-top: 10px;
                padding-left: 5px;
                padding-bottom: 30px;
            }
    
    .field-main-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-left: 100px;
        padding-right: 100px;
    }
    
    .field-container {
        display: flex;
        flex-direction: column;
    }
    
    .field-name {
        font-size: 9pt;
        padding-left: 10px;
    }
    
    .field {
        font-size: 12pt;
        padding: 20px;
        border-radius: 5px;
        border-style: solid;
        border-width: 1.5px;
        border-color: #c4c4c4;
        box-shadow: none;
    }

        .field:focus {
            outline-color: #5b346b;
        }
    
    .option-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-top: 30px;
        padding-left: 100px;
        padding-right: 100px;
    }
}

.sign-up-button {
    font-size: 13pt;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    color: #5b346b;
    cursor: pointer;
}

    .sign-up-button:hover {
        color: #6d3f80;
    }

    .sign-in-button:active {
        color: #5b346b;
    }

.sign-in-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #5b346b;
    width: 100px;
    height: 50px;
    border-radius: 10px;
    cursor: pointer;

    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

    .sign-in-text {
        font-size: 13pt;
        font-weight: bold;
        font-family: Arial, Helvetica, sans-serif;
        color: #ffffff;
    }

    .sign-in-button:hover {
        background-color: #6d3f80;
    }

    .sign-in-button:active {
        background-color: #5b346b;
    }