.colored-button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 80px;
    height: 40px; 
    border-radius: 5px;
    cursor: pointer;
    padding: 10px;

    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

    .colored-button-text {
        font-size: 12pt;
        font-weight: bold;
        font-family: Arial, Helvetica, sans-serif;
    }