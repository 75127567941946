.billing-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
}

    .billing-header {
        display: flex;
        flex-direction: column;
        padding: 20px;
        border: 1px;
        border-radius: 10px;
        box-shadow: 2px 2px 2px 2px #efefef;
        background-color: #ffffff;
        gap: 10px;
    }

        .billing-header-text {
            font-size: 18pt;
            font-weight: bold;
        }

    .billing-box-container {
        display: flex;
        flex-direction: column;
        padding: 20px;
        border: 1px;
        border-radius: 10px;
        box-shadow: 2px 2px 2px 2px #efefef;
        background-color: #ffffff;
        gap: 10px;
    }

    .procedure-modal-header-title {
        color: #a0a0a0;
        font-size: 12px;
    }

    .procedure-modal-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 20px;
        border-bottom: 1px solid #efefef;
    }

        .procedure-modal-header-text {
            font-size: 15pt;
            font-weight: bold;
        }

        .procedure-modal-header-procedure-status {
            width: fit-content;
            padding-left: 10px;
            padding-right: 10px;
            border: 1px;
            border-radius: 15px;
        }

            .procedure-modal-header-procedure-status.incomplete {
                background-color: rgb(255, 94, 94);;
            }

    .procedure-modal-body {
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        padding-bottom: 20px;
    }

        .procedure-modal-procedure-description-title {
            color: #a0a0a0;
        }

        .procedure-modal-procedure-description {
            padding-left: 20px;
        }

        .procedure-model-procedure-cost-title {
            color: #a0a0a0;
        }

        .procedure-model-procedure-cost {
            color: #444444;
            font-family: 'Roboto', sans-serif;
            font-size: 18pt;
            font-weight: bold;
        }

    .billingpage-balance-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

        .billingpage-balance-box {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 20px;
            border: 1px;
            border-radius: 10px;
            box-shadow: 2px 2px 2px 2px #efefef;
            background-color: #ffffff;
            gap: 10px;
        }

            .billingpage-balance-box-header {
                font-size: 18pt;
                font-weight: bold;
                padding-bottom: 20px;
                border-bottom: 1px solid #efefef;
            }

            .billingpage-balance-box-contents-recent-payable {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding-top: 10px;
            }

                .billingpage-balance-box-contents-recent-payable-title {
                    color: #c4c4c4;
                }

            .billingpage-balance-box-contents-previous-balance {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding-bottom: 20px;
                border-bottom: 1px solid #efefef;
            }

                .billingpage-balance-box-contents-previous-balance-title {
                    color: #c4c4c4;
                }

            .billingpage-balance-box-contents-balance {
                display: flex;
                flex-direction: column;
                padding-top: 20px;
            }

                .billingpage-balance-box-contents-balance-title {
                    color: #c4c4c4;
                    font-weight: bold;
                }

                .billingpage-balance-box-contents-balance-amount {
                    color: #444444;
                    font-family: 'Roboto', sans-serif;
                    font-size: 21pt;
                    font-weight: bold;
                }

@media screen and (max-width: 768px) {
    .billing-box-row {
        display: grid;
        grid-template-columns: repeat(3, 33.33%[col-start]);
    }

        .billing-box-header {
            font-weight: bold;
        }

        .billing-box-item {
            display: inline-block;
            width: 90%;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            -ms-text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
        }

    .billing-box-row-header {
        display: grid;
        grid-template-columns: repeat(3, 33.33%[col-start]);
    }

        .billing-box-item-cost {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
        }

            .billing-box-item-cost.incomplete {
                border: 1px;
                border-radius: 10px;
                background-color: rgb(255, 94, 94);
            }

    .procedure-modal {
        position: fixed;
        top: 50%;
        bottom: 50%;
        left: 50%;
        right: 50%;
        transform: translate(-50%, -50%);

        width: 80%;
        height: fit-content;

        padding: 20px;
        border: 1px;
        border-radius: 10px;
        box-shadow: 2px 2px 2px 2px #efefef;
        background-color: #ffffff;
    }
}

@media screen and (min-width: 769px) {
    .billing-box-row {
        display: grid;
        grid-template-columns: repeat(4, 25%[col-start]);
    }

        .billing-box-header {
            font-weight: bold;
        }

        .billing-box-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            word-wrap: break-word;
            word-break: break-all;
        }

    .billing-box-row-header {
        display: grid;
        grid-template-columns: repeat(4, 25%[col-start]);
    }

        .billing-box-procedure-status-circle {
            width: 10px;
            height: 10px;
            border: 1px;
            border-radius: 50%;
            background-color: red;
            margin-left: 20px;
        }

    .procedure-modal {
        position: fixed;
        top: 50%;
        bottom: 50%;
        left: 50%;
        right: 50%;
        transform: translate(-50%, -50%);

        width: 400px;
        height: fit-content;

        padding: 20px;
        border: 1px;
        border-radius: 10px;
        box-shadow: 2px 2px 2px 2px #efefef;
        background-color: #ffffff;
    }
}