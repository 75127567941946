.civil-details-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 10%;
}

.civil-details-container-fields {
    display: flex;
    flex-direction: column;
}

.civil-details-container-options {
    display: flex;
    flex-direction: row-reverse;
}
