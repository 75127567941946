.textfield {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.textfield-title {
    font-size: 9pt;
    padding-left: 10px;
}

.textfield-box {
    font-size: 12pt;
    padding: 20px;
    border-radius: 5px;
    border-style: solid;
    border-width: 1.5px;
    border-color: #c4c4c4;
    box-shadow: none;
    width: 100%;
}

    .textfield-box {
        outline-color: #5b346b;
    }

.textfield-prompt {
    font-size: 9pt;
    padding-left: 10px;
}
.textfield-placeholder {
    color: rgba(78, 76, 76, 0.5); /* Adjust opacity to achieve faded effect */
}