.name-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 10%;
}

.name-container-fields {
    display: flex;
    flex-direction: column;
}

    .name-container-fields-middle-and-last-name {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

.name-container-options {
    display: flex;
    flex-direction: row-reverse;
}