.appointment-card {
    border: 1px;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 2px #efefef;
    background-color: #ffffff;
    padding: 10px;
}

.appointment-card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    padding-left: 10px;
    border-bottom: 1px solid #a3a3a3;
}

    .appointment-card-header-status {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
    }

        .appointment-card-header-status-light {
            height: 10px;
            width: 10px;
            border-radius: 50%;
        }

    .appointment-card-header-date {
        color: #a3a3a3;
    }

.appointment-card-body {
    padding-top: 10px;
    padding-bottom: 10px;
}

    .appointment-card-body-for {
        font-size: 13pt;
    }

    .appointment-card-body-dentist {
        color: #a3a3a3;
    }

.appointment-card-footer {
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
}

    .appointment-card-footer-button {
        text-align: center;
        padding: 10px;
        border: 1px;
        border-radius: 10px;
        border-style: solid;
        border-color: #c4c4c4;
        cursor: pointer;
        transition: background-color 100ms;

        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
    }

        .appointment-card-footer-button:hover {
            background-color: #efefef;
        }

        .appointment-card-footer-button:active {
            border-color: transparent;
        }
        /* Additional CSS for modals */
.reschedule-modal,
.cancel-reason-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.reschedule-modal h2,
.cancel-reason-modal h2 {
    margin-top: 0;
    font-size: 18px;
    color: #333;
}

.reschedule-modal table,
.cancel-reason-modal table {
    width: 100%;
    border-collapse: collapse;
}

.reschedule-modal table td,
.cancel-reason-modal table td {
    padding: 8px;
}

.reschedule-modal table label,
.cancel-reason-modal table label {
    font-weight: bold;
}

.reschedule-modal table input[type="datetime-local"],
.cancel-reason-modal table input[type="text"] {
    width: calc(100% - 16px);
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
}

.reschedule-modal table input[type="submit"],
.cancel-reason-modal table input[type="submit"] {
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.reschedule-modal table input[type="submit"]:hover,
.cancel-reason-modal table input[type="submit"]:hover {
    background-color: #0056b3;
}
