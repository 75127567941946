.inbox-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
}

    .inbox-header {
        display: flex;
        flex-direction: column;
        padding: 20px;
        border: 1px;
        border-radius: 10px;
        box-shadow: 2px 2px 2px 2px #efefef;
        background-color: #ffffff;
        gap: 10px;
    }

        .inbox-header-title {
            font-size: 18pt;
            font-weight: bold;
        }

    .inbox-body {
        display: flex;
        flex-direction: column;
        padding: 20px;
        border: 1px;
        border-radius: 10px;
        box-shadow: 2px 2px 2px 2px #efefef;
        background-color: #ffffff;
        gap: 10px;
    }

        .inbox-notification {
            display: flex;
            flex-direction: column;
            border-left: 10px solid;
            border-color: #efefef;
            padding-left: 10px;
            padding-top: 10px;
            padding-bottom: 10px;
            max-height: 92px;
            overflow: hidden;
        }

            .inbox-notification.opened {
                max-height: 1000px;
            }

            .inbox-notification-header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }

                .inbox-notification-title-container {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }

                    .inbox-notification-title {
                        font-weight: bold;
                        padding-left: 10px;
                    }
                
                .inbox-notification-unread {
                    width: 10px;
                    height: 10px;
                    border: 1px;
                    border-radius: 50%;
                    background-color: red;
                }

            .inbox-notification-date {
                color: #9b9b9b;
            }

            .inbox-notification-description {
                display: inline-block;
                width: 100%;

                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                -ms-text-overflow: ellipsis;
                -o-text-overflow: ellipsis;
            }

                .inbox-notification-description.opened {
                    white-space: pre-wrap;
                }