.home-page {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.main-container {
    display: flex;
    flex-direction: row;
    overflow-y: auto;
    flex: 1;
    width: 100%;
    gap: 0px;
}

.medical-record {
    background-color: #ffffff;
}

@media only screen and (max-width: 768px) {
    .page-container {
        overflow-y: auto;
        flex: 1;
        margin-right: -190px;
        transition: transform 0.3s ease;
        background-color: #fafafa;
    }
    
    .page-container.hidden-nav {
        transform: translateX(-190px);
    }

    .medical-record {
        padding: 20px 0px 20px 20px;
        top: 70px;
        height: fit-content;
        transform: translate(0, 70px);
    }
}

@media only screen and (min-width: 769px) {
    .page-container {
        overflow-y: auto;
        flex: 1;
        transition: margin-left 0.3s ease;
        background-color: #fafafa;
    }
    
    .page-container.hidden-nav {
        margin-left: -190px;
    }

    .medical-record {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        width: 768px;
        padding: 20px;

        border: 1px;
        border-radius: 10px;
        box-shadow: 2px 2px 2px 2px #efefef;
    }
}