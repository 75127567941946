.clinic-address-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 10%;
}

.clinic-address-container-fields {
    display: flex;
    flex-direction: column;
}

    .clinic-address-container-fields-bottom {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

.clinic-address-container-options {
    display: flex;
    flex-direction: row-reverse;
}