.personal-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 10%;
}

.personal-details-fields-top {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.personal-details-fields-bottom {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.personal-details-container-options {
    display: flex;
    flex-direction: row-reverse;
}

.error-message {
    color: red;
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 12px;
}
