.medical-history-container {
    display: flex;
    flex-direction: column;
}

    /* modal */

    .question-options-modal {
        position: absolute;

        padding: 20px;
        border: 1px;
        border-radius: 10px;
        box-shadow: 2px 2px 2px 2px #efefef;
        background-color: #ffffff;
    }

    /* modal end */

    .medical-history-container-header {
        padding-bottom: 10px;
    }

        .medical-history-container-header-text {
            font-size: 18pt;
            font-weight: bold;
        }

    .medical-history-container-body {
        display: flex;        
        flex-direction: column;
        padding-right: 20px;
        gap: 10px;
        overflow: auto;
    }

        .medical-history-container-question-fields {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            gap: 10px;
        }

        .medical-history-container-question-6-field {
            display: flex;
            flex-direction: row;
            width: 100%;
        }

    .medical-history-container-footer {        
        display: flex;
        flex-direction: row-reverse;
        padding-top: 20px;
    }

    .dental-history-question-seven-table {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-column-gap: 10px;
        grid-row-gap: 10px;
        align-items: center;
        padding: 10px 10px 0px 20px;
    }

@media screen and (max-width: 768px) {
    .question-options-modal {
        width: 100%;
        top: 70px;
        height: fit-content;
        transform: translate(0, 70px);
    }

    .medical-history-container-body {
        max-height: 65vh;
        overflow-y: auto;
    }

    .medical-history-container-footer {
        padding-right: 20px;
    }
}

@media screen and (min-width: 769px) and (max-width: 1274px) {
    .question-options-modal {
        width: calc(100% - 15vh);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .medical-history-container-body {
        grid-column-gap: 20px;
        max-height: 75vh;
    }
}

@media screen and (min-width: 1275px) {
    .question-options-modal {
        width: calc(100% - 50vh);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .medical-history-container-body {
        grid-column-gap: 20px;
        max-height: 75vh;
    }
}