.question-modal-container {
    display: flex;
    flex-direction: column;
}

    .question-modal-header {
        padding-bottom: 20px;
        color: #5b346b;
    }

        .question-modal-header-text {
            font-size: 15pt;
        }

    .question-modal-body {
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
    }

        .question-modal-option {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

            .question-modal-option-radio {
                display: flex;
                flex-direction: row;
                gap: 10px;
            }

    .question-modal-footer {
        display: flex;
        flex-direction: row-reverse;
    }

@media screen and (max-width: 768px) {
    .question-modal-body {
        overflow: auto;
        max-height: 50vh;
    }

        .question-modal-option {
            width: 100%;
        }
}

@media screen and (min-width: 769px) {
    .question-modal-body {
        flex-wrap: wrap;
        max-height: 40vh;
    }

        .question-modal-option {
            width: calc(100% / 5);
        }
}