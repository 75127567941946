.medical-history-item-question {
    width: 100%;
    padding-top: 10px;
    border-top: 1px solid #cacaca;
}

.medical-history-item-header {
    display: flex;
    flex-direction: row;
    gap: 20px
}

    .medical-history-item-question-text {
        height: auto;
    }

    .medical-history-item-question-yes-or-no-radio-container { 
        display: flex;
        flex-direction: row;
        gap: 20px
    }

        .medical-history-item-question-yes-or-no-radio {
            display: flex;
            flex-direction: row;
            gap: 5px;
        }