.topbar {
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    height: 70px;
    border: 0px;
    border-bottom: 1px;
    border-style: solid;
    border-color: #c4c4c4;

    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.topbar-red-circle {
    display: none;
    position: absolute;
    z-index: 1;
    top: 35px;
    left: 15px;

    width: 10px;
    height: 10px;
    margin-left: 32px;
    border: 1px;
    border-radius: 50%;
    background-color: red;
}

    .topbar-red-circle.active {
        display: block;
    }

        .topbar-red-circle.active.mobile {
            display: block;
            top: 43px;
            left: 20px;
        }

.logo {
    position: absolute;
    left: 100px;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3%;
}

    .logo-icon {
        height: 40px;
    }
    
    .logo-img {
        height: 20px;
    }

@media only screen and (max-width: 768px) {

    .hamburger {
        display: none;
    }

    .account {
        position: absolute;
        left: 25px;

        height: 35px;
        border-radius: 50%;
        border-style: solid;
        border-width: 1px;
        border-color: #c4c4c4;
        transition: 500ms;
    }

        .account-img {
            height: 35px;
        }
    
}

@media only screen and (min-width: 769px) {

    .account {
        position: absolute;
        right: 25px;

        height: 35px;
        width: 35px;
        border-radius: 50%;
        transition: 500ms;
    }

        .account-img {
            height: 35px;
        }

        .account:hover {
            -webkit-filter: brightness(95%);
        }

    .hamburger {
        position: absolute;
        left: 25px;

        display: flex;
        justify-content: center;
        align-items: center;
        height: 35px;
        width: 35px;
        border-radius: 50%;
        transition: 700ms;

        cursor: pointer;
    }

        .hamburger:hover {
            background-color: #efefef;
        }

    .name {
        position: absolute;
        right: 70px;
        top: 23px;
    }

}