.sidenavigation {
    width: 210px;
    border: 0px;
    border-right: 1px;
    border-style: solid;
    border-color: #c4c4c4;
    padding: 20px;
    transition: transform 0.3s ease;

    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.sidenavigation.hidden-nav {
    transform: translateX(-190px);
}

.navigation-selection {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    height: 40px;
    border-radius: 20px;
    padding-left: 20px;
    border: 1px;
}

    .navigation-selection:hover {
        background-color: #efefef;
    }

    .active .navigation-selection{
        background-color: #c3b1e1;
    }

.navigation-notification-red-circle {
    width: 10px;
    height: 10px;
    margin-left: 32px;
    border: 1px;
    border-radius: 50%;
    background-color: red;
}

.name {
    padding: 0px 20px 10px 5px;
}